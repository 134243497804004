import React from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsCheck2Circle, BsExclamationDiamond } from "react-icons/bs";
import styled from "styled-components";
import { PastClassStatusStyles } from "../../components/Cards/CardFootstep/CardFootstep";

export function PastClassSubtitle({ classes }: ClassesThatAreLateSubtitleProps) {
  return (
    <>
      {classes.length > 0 &&
        <SubtitlesStyles md={12}>
          <Row>
            <Col>
              <Row>
                <h1>
                  Status de finalização 
                </h1>
              </Row>
              <Row>
                <Col md='auto'>
                  <BsCheck2Circle color="#008555" size={30} />
                </Col>
                <Col className="my-auto">
                  <span style={{ color: "#008555" }}>segunda-feira, 19/02/2024</span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="my-auto">
                  <h2>
                    Aula finalizada menos de 50 minutos antes do horário
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col md='auto'>
                  <BsExclamationDiamond color="#b0ab28" size={30} />
                </Col>
                <Col className="my-auto">
                  <span style={{ color: "#b0ab28" }}>segunda-feira, 19/02/2024</span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="my-auto">
                  <h2>
                    Aula finalizada mais de 50 minutos antes do horário
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col md='auto'>
                  <AiOutlineCloseCircle color="#D43B26" size={30} />
                </Col>
                <Col className="my-auto">
                  <span style={{ color: "#D43B26" }}>segunda-feira, 19/02/2024</span>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col className="my-auto">
                  <h2>
                    Aula não realizada
                  </h2>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <h1>
                  Status de Pontualidade 
                </h1>
              </Row>
              <Row className="mb-1">
                <Col md={3}>
                  <PastClassStatusStyles color="#008555" className="text-center mx-auto">
                    <span>Regular</span>
                  </PastClassStatusStyles>
                </Col>
                <Col className="my-auto">
                  <h2>
                    Aula iniciada no horário previsto
                  </h2>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col md={3}>
                  <PastClassStatusStyles color="#A56300" className="text-center mx-auto">
                    <span>Atraso Normal</span>
                  </PastClassStatusStyles>
                </Col>
                <Col className="my-auto">
                  <h2>
                    Aula iniciada com menos de 15 minutos de atraso
                  </h2>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col md={3}>
                  <PastClassStatusStyles color="#D43B26"  className="text-center mx-auto">
                    <span>Atraso Crítico</span>
                  </PastClassStatusStyles>
                </Col>
                <Col className="my-auto">
                  <h2>
                    Aula iniciada com mais de 15 minutos de atraso
                  </h2>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col md={3}>
                  <PastClassStatusStyles color="#727171" className="text-center mx-auto">
                    <span>Não Realizada</span>
                  </PastClassStatusStyles>
                </Col>
                <Col className="my-auto">
                  <h2>
                    Aula não realizada
                  </h2>
                </Col>
              </Row>
            </Col>
          </Row>
        </SubtitlesStyles>}
    </>
  );
}
interface ClassesThatAreLateSubtitleProps {
  classes: any[];
}
const SubtitlesStyles = styled(Col)`
  h2{
    font-family: "Segoe UI";
    font-style: normal;
    font-weight: 400;
    font-size: 1.8vmin;
    line-height: 2.22vmin;
    color: rgb(74, 74, 74);
    margin-bottom:0px;
  }
  h1{
    font-family: "Segoe UI";
    font-style: normal;
    font-weight: 700;
    font-size: 1.94vmin;
    line-height: 2.78vmin;
    color: #636060;
  }
`;


