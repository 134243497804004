import React, { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import CardToday from "../../../components/Cards/CardToday/CardToday";
import { MainContext } from "../../../context/MainContext";
import { PaginationLayout } from "../../PaginationLayout";
import styled from "styled-components";

const ClassesHappeningNowListComponent: React.FC = ({}) => {
  const {
    classes, 
    currentPage, 
    loadClassesHappeningNow,
    getSearchInputTextValue
  } = useContext(MainContext)

  useEffect(() => {
    let isFiltering:boolean = getSearchInputTextValue()!==""
    loadClassesHappeningNow(currentPage,isFiltering)
  }, [currentPage]);

  return (
    <>
      <PaginationLayout
        reload={loadClassesHappeningNow}
        subtitle={
          <ClassesThatAreHappeningNowSubtitle
            classes={classes}
          />}
      >
        {classes.map((item: any) => (
          <Col md={"auto"} className="pe-0">
            <CardToday
              percentStudents={item.percentStudents}
              teacherParticipated={item.teacherParticipated}
              date={item.date}
              descrpiton={item.description}
              startTime={item.startTime}
              endTime={item.endTime}
              name={item.name}
              teachers={item.teachers}
              statusClass={item.statusClass}
              channelWebUrl={item.channelWebUrl}
              hasJoinUrl={item.hasJoinUrl}
              joinUrl={item.joinUrl}
            />
          </Col>
        ))}
      </PaginationLayout>
    </>
  );
};

interface ClassesSubtitleProps{
  classes:any[]
}

function ClassesThatAreHappeningNowSubtitle ({classes}:ClassesSubtitleProps){
  return (
    <>
        {
          classes.length >0 &&
          <SubtitlesStyles md={12}>
            <Row>
              <Col>
                <Row>
                  <Col md='auto'>
                    <i className="bi bi-check-circle"  style={{color:"#008555", fontSize:"3vmin"}}/>
                  </Col>
                  <Col className="my-auto">
                    <span style={{ color: "#008555" }}>Acontecendo Agora</span>
                  </Col>
                </Row>
                <Row>
                  <Col className="my-auto">
                    <h2 >
                      Professor se encontra presente na aula
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col md='auto'>
                    <i className="bi bi-alarm"  style={{color:"#A56300", fontSize:"3vmin"}}/>
                  </Col>
                  <Col className="my-auto">
                    <span style={{ color: "#A56300" }}>Aula Atrasada</span>
                  </Col>
                </Row>
                <Row>
                  <Col className="my-auto">
                    <h2 >
                      Professor está atrasado menos de 15 minutos
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col md='auto'>
                    <i className="bi bi-x-circle"  style={{color:"#D43B26", fontSize:"3vmin"}}/>
                  </Col>
                  <Col className="my-auto">
                    <span style={{ color: "#D43B26" }}>Atraso Critico</span>
                  </Col>
                </Row>
                <Row>
                  <Col className="my-auto">
                    <h2>
                      Professor está atrasado mais de 15 minutos
                    </h2>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col md='auto'>
                    <i className="bi bi-person-dash"  style={{color:"#b0ab28", fontSize:"3vmin"}}/>
                  </Col>
                  <Col className="my-auto">
                    <span style={{ color: "#b0ab28" }}>Professor Ausente</span>
                  </Col>
                </Row>
                <Row>
                  <Col className="my-auto">
                    <h2>
                      Esteve online durante a aula e <br/> 
                      passaram-se menos de 50 minutos de sua última saída registrada
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col md='auto'>
                    <i className="bi bi-check-circle"  style={{color:"#29bc87", fontSize:"3vmin"}}/>
                  </Col>
                  <Col className="my-auto">
                    <span style={{ color: "#29bc87" }}>Aula finalizada mais cedo</span>
                  </Col>
                </Row>
                <Row>
                  <Col className="my-auto">
                    <h2>
                      Esteve online durante a aula e <br/>
                      passaram-se mais de 50 minutos de sua última saída registrada
                    </h2>
                  </Col>
                </Row>
              </Col> 
            </Row>
          </SubtitlesStyles>
        }
    </>
  )
}

const SubtitlesStyles = styled(Col)`
  h2{
    font-family: "Segoe UI";
    font-style: normal;
    font-weight: 400;
    font-size: 1.7vmin;
    line-height: 2.22vmin;
    color: rgb(74, 74, 74);
  }
`
export default ClassesHappeningNowListComponent;





